<!-- eslint-disable vuejs-accessibility/no-static-element-interactions -->
<template>
  <div
    v-if="review"
    class="review-card bg-white rounded-md flex flex-col gap-y-3 my-5 p-2 xxs:p-5 shadow-card font-sans"
  >
    <div class="gap-x-2 border-b border-athens pb-3">
      <div
        class="flex flex-row w-full justify-between xs:h-auto items-baseline h-14 overflow-y-hidden"
      >
        <a
          class="xs:text-mdnf md:text-basenf leading-6 font-bold text-black hover:underline"
          target="_blank"
          :href="`/${review.airport_content_slug}/${review.merchant_slug}.html`"
        >
          {{ review.merchant }}
        </a>

        <div class="xxs:text-base md:text-basenf font-bold flex items-center">
          <Stars
            class="block w-6"
            :rating="1"
            :hide-over-rating="true"
            :small="true"
          />
          {{ score }}
        </div>
      </div>
      <div class="text-mdnf leading-normal text-manatee w-full">
        {{
          $i18n('merchant.review-parked-between-dates', {
            start: review.arrival ? arrival : '',
            end: review.departure ? departure : '',
          })
        }}
      </div>
    </div>
    <div
      ref="wrapper"
      class="review-card__content-wrapper border-b border-athens pb-3 box-content relative"
      :class="{ 'max-h-[420px]': expanded, 'h-[110px]': !expanded }"
    >
      <div
        ref="content"
        class="font-sans md:text-base"
        style="line-height: 1.88"
      >
        <div
          ref="reviewContent"
          class="overflow-hidden break-words"
          :class="{
            'overflow-y-auto !max-h-[400px]': expanded,
            'h-[90px]': !expanded,
          }"
        >
          {{ review.content }}
        </div>

        <a
          v-if="!expanded && showMoreButton"
          class="text-manatee hover:underline hover:text-blue-600 cursor-pointer lowercase mb-2.5"
          @click="handleReviewCardHeight"
          @keypress="handleReviewCardHeight"
        >
          {{ '...' + $i18n('templates.read-more') }}
        </a>
        <a
          v-if="expanded"
          class="text-manatee hover:underline hover:text-blue-600 cursor-pointer lowercase mb-2.5"
          @click="handleReviewCardHeight"
          @keypress="handleReviewCardHeight"
        >
          {{ $i18n('templates.read-less') }}
        </a>
      </div>
    </div>
    <div class="flex place-content-between place-items-center">
      <div class="flex items-center">
        <div class="font-bold text-mdnf capitalize">
          {{ review.name }}
        </div>
        <div class="ml-3 text-manatee text-mdnf hidden sm:block">
          {{ parkingType }}
        </div>
      </div>
      <div class="text-manatee text-mdnf">
        {{ date }}
      </div>
    </div>
  </div>
</template>

<script>
import Stars from '~/components/header/MetaReviews/Stars.vue';

export default {
  components: {
    Stars,
  },
  props: {
    review: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      expanded: false,
      showMoreButton: false,
    };
  },
  computed: {
    parkingType() {
      return this.$i18n(`general.${this.review.parking_type}`);
    },
    language() {
      return this.$store.state.language;
    },
    date() {
      return this.formatDate(this.review.date);
    },
    arrival() {
      return this.formatDate(this.review.arrival, 'short');
    },
    departure() {
      return this.formatDate(this.review.departure, 'short');
    },
    score() {
      if (this.language.lang === 'en-us') {
        return this.numberFormat(
          (this.review.score / 2).toFixed(1).replace(/[.,]0$/, '')
        );
      }
      return this.numberFormat(
        this.review.score.toFixed(1).replace(/[.,]0$/, '')
      );
    },
    mappedScore() {
      return (+this.review.score / 10) * 5;
    },
  },
  mounted() {
    if (this.$el) {
      this.showMoreButton =
        this.$refs.reviewContent?.offsetHeight <
        this.$refs.reviewContent?.scrollHeight - 20;
    }
  },
  methods: {
    formatDate(date, dateStyle = 'long') {
      return new Intl.DateTimeFormat(this.language.lang, {
        dateStyle,
      }).format(new Date(date));
    },
    numberFormat(number) {
      return new Intl.NumberFormat(this.language.lang).format(number);
    },
    handleReviewCardHeight() {
      this.expanded = !this.expanded;
      this.$refs.reviewContent.scrollTop = 0;
    },
  },
};
</script>

<style lang="scss">
.review-card {
  @media all and (min-width: 599px) and (max-width: 790px) {
    @apply mx-3;
  }
  &__content-wrapper {
    min-height: calc(28px * 2);
    @media only screen and (max-width: 770px) {
      min-height: calc(28px * 2);
    }
  }
}
</style>
