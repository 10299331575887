<!-- eslint-disable vue/html-self-closing -->
<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<!-- eslint-disable vuejs-accessibility/no-static-element-interactions -->
<!-- eslint-disable vuejs-accessibility/mouse-events-have-key-events -->
<template>
  <div class="carousel-container">
    <div
      ref="carousel"
      class="carousel-wrapper"
      @mousedown="onMouseDown"
      @mousemove="onMouseMove"
      @mouseup="stopDragging"
      @mouseleave="stopDragging"
    >
      <slot name="slides" />
    </div>
    <button
      v-if="!isFirstSlide"
      class="carousel-arrow -left-1"
      id="locations_reviews_left_button"
      @click="slidePrevious"
    >
      <svg
        class="fill-current"
        viewBox="0 0 24 24"
        aria-label="Arrow pointing to the left"
      >
        <path
          d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"
        ></path>
      </svg>
    </button>
    <button
      v-if="!isLastSlide"
      class="carousel-arrow -right-1"
      @click="slideNext"
      id="locations_reviews_right_button"
    >
      <svg
        class="fill-current"
        viewBox="0 0 24 24"
        aria-label="Arrow pointing to the right"
      >
        <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"></path>
      </svg>
    </button>
    <div class="carousel-bullets">
      <span
        v-for="(bullet, index) in bulletCount"
        :key="`carousel-${index}`"
        class="carousel-bullet"
        :class="{ active: isActiveBullet(index) }"
        @click="scrollToSlide(index)"
      ></span>
    </div>
  </div>
</template>

<script>
import { yieldToMain } from '~/helpers/YieldToMain';

export default {
  props: {
    slidesToShow: {
      type: Number,
      default: 1,
    },
    slides: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      currentIndex: 0,
      slideWidth: 0,
      isFirstSlide: true,
      isLastSlide: false,
      isDragging: false,
      startX: 0,
      scrollLeft: 0,
    };
  },

  computed: {
    bulletCount() {
      return this.slides.length - this.slidesToShow + 1;
    },
    isMobile() {
      if (process.server) {
        return true;
      }
      return this.$screens({ xs: false }, true);
    },
  },

  mounted() {
    this.updateSlideWidth();
    this.initObserver();
    window.addEventListener('resize', this.updateSlideWidth);
    if (this.isMobile) {
      // eslint-disable-next-line vue/no-mutating-props
      this.slidesToShow = 1;
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.updateSlideWidth);
  },

  methods: {
    updateSlideWidth() {
      this.$nextTick(() => {
        if (this.$refs.carousel) {
          this.slideWidth = this.$refs.carousel.clientWidth / this.slidesToShow;
          document.documentElement.style.setProperty(
            '--slide-width',
            `${this.slideWidth}px`
          );
        }
      });
    },

    initObserver() {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry, index) => {
            const position =
              [...this.$refs.carousel.children].indexOf(entry.target) - index;

            if (entry.isIntersecting) {
              this.currentIndex = position;
              this.isFirstSlide = position === 0;
              this.isLastSlide =
                position >= this.slides.length - this.slidesToShow;
            }
          });
        },
        { root: this.$refs.carousel, threshold: 0.5 }
      );

      this.$refs.carousel.querySelectorAll('.ref-slide').forEach((slide) => {
        observer.observe(slide);
        yieldToMain();
      });
    },

    computeSlideWidth() {
      return this.$refs.carousel.querySelector('.ref-slide').scrollWidth + 20;
    },

    slideNext() {
      this.$refs.carousel.scrollBy({
        left: this.computeSlideWidth(),
        behavior: 'smooth',
      });
    },

    slidePrevious() {
      this.$refs.carousel.scrollBy({
        left: -this.computeSlideWidth(),
        behavior: 'smooth',
      });
    },

    scrollToSlide(index) {
      if (this.$refs.carousel) {
        const targetPosition = index * this.computeSlideWidth();
        this.$refs.carousel.scrollTo({
          left: targetPosition,
          behavior: 'smooth',
        });
        this.currentIndex = index;
      }
    },

    isActiveBullet(index) {
      return this.currentIndex === index;
    },

    onMouseDown(e) {
      this.isDragging = true;
      this.startX = e.pageX - this.$refs.carousel.offsetLeft;
      this.scrollLeft = this.$refs.carousel.scrollLeft;
    },

    onMouseMove(e) {
      if (!this.isDragging) return;

      const x = e.pageX - this.$refs.carousel.offsetLeft;
      const walk = (x - this.startX) * 2; // Adjust the scroll speed
      this.$refs.carousel.scrollLeft = this.scrollLeft - walk;
    },

    stopDragging() {
      this.isDragging = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.carousel-container {
  @apply relative select-none;
}

.carousel-wrapper {
  @apply flex overflow-x-auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  scrollbar-width: none;
}

.carousel-wrapper > * {
  flex: 0 0 50%;
  scroll-snap-align: start;
  @media (max-width: 768px) {
    flex: 0 0 100%;
  }
}

.carousel-arrow {
  @apply hidden md:block absolute bg-white border-none cursor-pointer w-8 h-8 shadow-icon hover:text-blue-500;
  top: calc(50% - 16px);
  border-radius: 50%;
  z-index: 2;
}

.left-arrow {
  @apply left-2;

  &:hover {
    filter: none;
  }
}

.right-arrow {
  @apply right-2;
  filter: grayscale(1) saturate(0) brightness(70%) contrast(1000%);
  background-size: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(https://assets.parkos.com/assets/img/static/arrow.png);

  &:hover {
    filter: none;
  }
}

.carousel-bullets {
  @apply hidden md:flex absolute left-2/4 -bottom-3 gap-2;
  transform: translateX(-50%);
}

.carousel-bullet {
  @apply text-manatee-500 w-2 h-2 bg-manatee-500 cursor-pointer;
  border-radius: 50%;
}

.carousel-bullet.active {
  @apply bg-blue-600;
}

@media only screen and (max-width: 1366px) {
  .carousel-wrapper > * {
    width: calc(100% / 2);
  }
}

@media only screen and (max-width: 990px) {
  .carousel-wrapper > * {
    @apply w-full;
  }
}

@media only screen and (max-width: 780px) {
  .carousel-wrapper {
  }

  .left-arrow,
  .right-arrow {
    display: none !important;
  }
}
</style>
