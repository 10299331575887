<template>
  <div
    class="reviews-wrapper flex flex-col py-10 md:py-20"
    id="locations_reviews"
  >
    <div
      class="flex flex-row place-content-between place-items-center font-sans mt-5 justify-between flex-wrap"
    >
      <div class="font-cocogoose text-lg md:text-xl w-11/12 sm:w-8/12 md:w-1/2">
        {{
          $i18n('templates.customer-parking-reviews-title', {
            airportName: airport.name,
            reviewsScoreAvg: totalScore,
          })
        }}
        <svg
          class="inline mb-1 md:mb-2"
          :width="16"
          :height="16"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="none" fill-rule="evenodd">
            <rect
              class="reviews-stars__star__background"
              fill="#f06b1f"
              width="20"
              height="20"
              rx="2"
            />
            <path
              class="reviews-stars__star__icon"
              fill="#fff"
              d="m10 12.614-3.919 2.78 1.433-4.586L3.66 7.94l4.804-.055L10 3.333l1.536 4.552 4.804.055-3.854 2.868 1.433 4.585z"
            />
            <rect width="10" height="20" rx="2" />
          </g>
        </svg>
      </div>
      <a
        v-if="airportReviewsContent && airportReviewsContent.slug"
        :href="airportReviewsLink"
        class="cursor-pointer w-full md:w-auto md:block text-blue-600 text-base md:text-base"
        id="locations_reviews_show_more_link"
      >
        {{ $i18n('general.read-more-reviews') }}
      </a>
    </div>
    <div class="-mx-2.5 md:-mx-3.75">
      <Carousel
        v-if="hasReviewsToShow"
        :slides="reviews"
        :slidesToShow="2"
        class="w-full"
      >
        <template #slides>
          <div
            v-for="review in reviews"
            :key="review.id"
            class="ref-slide md:max-w-1/2"
          >
            <ReviewCard class="mx-2.5 md:m-3.75" :review="review" />
          </div>
        </template>
      </Carousel>
    </div>
  </div>
</template>

<script>
import Carousel from '~/components/carousel/Carousel.vue';
import ReviewCard from '~/components/reviews/ReviewCard.vue';

export default {
  components: {
    Carousel,
    ReviewCard,
  },
  computed: {
    language() {
      return this.$store.state.language;
    },
    airportReviewsContent() {
      return this.$store.state.airportReviewsPageContent;
    },
    reviews() {
      return this.$store.state.reviews;
    },
    reviewsMeta() {
      return this.$store.state.reviewsMeta;
    },
    totalScore() {
      if (this.language.lang === 'en-us') {
        return this.numberFormat(
          (this.reviewsMeta.reviews.score / 2).toFixed(1).replace(/[.,]0$/, '')
        );
      }
      return this.numberFormat(
        this.reviewsMeta.reviews.score.toFixed(1).replace(/[.,]0$/, '')
      );
    },
    airport() {
      return this.$store.state.airport;
    },
    hasReviewsToShow() {
      return this.reviews && this.reviews.length;
    },
    count() {
      return this.reviewsMeta.reviews.count === 0
        ? 531
        : this.reviewsMeta.reviews.count;
    },
    airportReviewsLink() {
      if (!this.airportReviewsContent || !this.airportReviewsContent) {
        return '#';
      }
      return `${this.airportReviewsContent.slug}.html`;
    },
  },
  methods: {
    numberFormat(number) {
      return new Intl.NumberFormat(this.language.lang).format(number);
    },
  },
};
</script>

<style lang="scss">
.reviews-wrapper {
  content-visibility: auto;
}
</style>
